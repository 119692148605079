var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-10"
  }, [_vm._m(0), _c('div', {
    staticClass: "flex flex-row justify-center mobile-one-col"
  }, [_c('CardTile', {
    staticStyle: {
      "--duration": ".5s"
    },
    attrs: {
      "className": "slide-fade-right",
      "header": "Full Stack Engineer",
      "headerClass": "text-3xl"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_vm._v("Front and back end experience")];
      },
      proxy: true
    }])
  }), _c('CardTile', {
    staticStyle: {
      "--duration": "1s"
    },
    attrs: {
      "className": "slide-fade-right",
      "header": "Product Designer",
      "headerClass": "text-3xl"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_vm._v("Creating solutions for complex business needs")];
      },
      proxy: true
    }])
  }), _c('CardTile', {
    staticStyle: {
      "--duration": "1.5s"
    },
    attrs: {
      "className": "slide-fade-right",
      "header": "Scrum Master",
      "headerClass": "text-3xl"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_vm._v("Embedded Scrum Master on an Agile team")];
      },
      proxy: true
    }])
  }), _c('CardTile', {
    staticStyle: {
      "--duration": "2s"
    },
    attrs: {
      "className": "slide-fade-right",
      "header": "Project Manager",
      "headerClass": "text-3xl"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_vm._v("Over 2 years project management experience")];
      },
      proxy: true
    }])
  })], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-row justify-between mobile-one-col"
  }, [_c('div', {
    staticClass: "flex flex-column justify-center items-center",
    staticStyle: {
      "flex-basis": "40%"
    }
  }, [_c('div', {
    staticClass: "flex flex-col justify-center flex-nowrap text-6xl font-normal leading-normal mt-0 mb-1 p-2",
    staticStyle: {
      "color": "#60789e"
    }
  }, [_c('span', {
    staticClass: "slide-in-from-left"
  }, [_c('div', [_vm._v("Erik ")]), _c('div', [_vm._v("Dietrich")])]), _c('div', {
    staticClass: "fade-in flex flex-row justify-around text-2xl"
  }, [_c('a', {
    attrs: {
      "href": "https://www.linkedin.com/in/erik-dietrich-000506134/",
      "target": "_blank"
    }
  }, [_c('i', {
    staticClass: "hover-grow cursor-pointer fab fa-linkedin"
  })]), _c('a', {
    attrs: {
      "href": "https://github.com/erikd7",
      "target": "_blank"
    }
  }, [_c('i', {
    staticClass: "hover-grow cursor-pointer fab fa-github"
  })]), _c('a', {
    attrs: {
      "href": "https://wellfound.com/u/erik-dietrich-1",
      "target": "_blank"
    }
  }, [_c('i', {
    staticClass: "hover-grow cursor-pointer fab fa-angellist"
  })])])])]), _c('div', {
    staticClass: "fade-in mt-0 mb-1 p-2 flex flex-row justify-center",
    staticStyle: {
      "--duration": ".5s"
    }
  }, [_c('img', {
    staticClass: "image-display",
    attrs: {
      "src": require("../data/coverPhoto.jpeg")
    }
  })]), _c('div', {
    staticClass: "flex flex-col flex-grow justify-center items-stretch font-normal leading-normal mt-0 mb-1 p-2",
    staticStyle: {
      "color": "#60789e"
    }
  }, [_c('div', {
    staticClass: "slide-fade-right text-left text-4xl"
  }, [_vm._v(" Full stack engineering ")]), _c('div', {
    staticClass: "slide-fade-left text-center text-2xl"
  }, [_vm._v(" focused on process improvement and end-to-end user experience ")])])]);
}]

export { render, staticRenderFns }