var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "flex flex-row justify-center flex-nowrap mobile-one-col"
  }, [_c('CardTile', {
    attrs: {
      "header": "About this App",
      "headerClass": "text-3xl"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_vm._v(" I made this as a personal project to continue learning, house existing and future projects, and enjoy hobby coding! "), _c('CardTile', {
          attrs: {
            "header": "Resume",
            "hoverGrow": false
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function () {
              return [_vm._v(" My web resume")];
            },
            proxy: true
          }])
        }), _c('CardTile', {
          attrs: {
            "header": "Chess",
            "hoverGrow": false
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function () {
              return [_vm._v(" A player-vs-player chess application (in progress)")];
            },
            proxy: true
          }])
        }), _c('CardTile', {
          attrs: {
            "header": "Cribbage",
            "hoverGrow": false
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function () {
              return [_vm._v(" A points counter for the game of Cribbage")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }), _c('CardTile', {
    attrs: {
      "header": "About the Tech Stack",
      "headerClass": "text-3xl"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_c('CardTile', {
          attrs: {
            "header": "Front End",
            "hoverGrow": false
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function () {
              return [_c('ul', {
                staticClass: "list-disc list-inside text-left"
              }, [_c('li', [_vm._v("JavaScript")]), _c('li', [_vm._v("Vue.js Framework")]), _c('li', [_vm._v("Tailwind CSS Framework")]), _c('li', [_vm._v("Axios.js API Framework")])])];
            },
            proxy: true
          }])
        }), _c('CardTile', {
          attrs: {
            "header": "Back End - Python",
            "hoverGrow": false
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function () {
              return [_c('ul', {
                staticClass: "list-disc list-inside text-left"
              }, [_c('li', [_vm._v("Python API Server")]), _c('li', [_vm._v("Pip Package Management")]), _c('li', [_vm._v("Flask API Framework")]), _c('li', [_vm._v("Gunicorn WSGI Server Framework")]), _c('li', [_vm._v("Postman for testing")])])];
            },
            proxy: true
          }])
        }), _c('CardTile', {
          attrs: {
            "header": "DevOps",
            "hoverGrow": false
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function () {
              return [_c('ul', {
                staticClass: "list-disc list-inside text-left"
              }, [_c('li', [_vm._v("Git Source Control via GitHub")]), _c('li', [_vm._v("Hosting")]), _c('li', {
                staticStyle: {
                  "margin-left": "2em"
                }
              }, [_vm._v(" JS Front End ")]), _c('li', {
                staticStyle: {
                  "margin-left": "4em"
                }
              }, [_vm._v(" Auto deploy to Vercel via GitHub Actions ")]), _c('li', {
                staticStyle: {
                  "margin-left": "2em"
                }
              }, [_vm._v(" Python Back End ")]), _c('li', {
                staticStyle: {
                  "margin-left": "4em"
                }
              }, [_vm._v(" Auto deploy to Vercel via GitHub Actions ")]), _c('li', {
                staticStyle: {
                  "margin-left": "4em"
                }
              }, [_vm._v(" Resume and Chess use the API. There is also a wakeup function to start the serverless pod, which is free tier and not the fastest. ")])])];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }