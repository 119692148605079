var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ("card-container " + (_vm.className) + " " + (_vm.hoverGrow ? 'hover-grow' : '')),
    style: {
      '--bg-color': _vm.color,
      '--scale': 1.01
    }
  }, [_vm.header ? _c('div', {
    class: ("card-header " + (_vm.headerClass))
  }, [_vm._v(" " + _vm._s(_vm.header) + " "), _vm.subHeader ? _c('div', {
    staticClass: "card-subheader"
  }, [_vm._v(" " + _vm._s(_vm.subHeader) + " ")]) : _vm._e()]) : _c('div', [_vm._t("header")], 2), _c('div', [_vm._t("body")], 2), _c('div', {
    staticClass: "mt-auto"
  }, [_vm._t("footer")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }