var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.expenses, function (expense, index) {
    return _c('div', {
      key: index,
      staticClass: "flex flex-nowrap",
      staticStyle: {
        "width": "300px"
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: expense.label,
        expression: "expense.label"
      }],
      staticClass: "form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700\n        bg-white bg-clip-padding border border-solid border-gray-300 rounded m-1\n        transition ease-in-out m-0 ml-0\n        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
      attrs: {
        "placeholder": "label"
      },
      domProps: {
        "value": expense.label
      },
      on: {
        "focus": function ($event) {
          return $event.target.select();
        },
        "input": function ($event) {
          if ($event.target.composing) { return; }
          _vm.$set(expense, "label", $event.target.value);
        }
      }
    }), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: expense.amount,
        expression: "expense.amount"
      }],
      ref: "amount",
      refInFor: true,
      staticClass: "form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700\n        bg-white bg-clip-padding border border-solid border-gray-300 rounded m-1\n        transition ease-in-out m-0 ml-0\n        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
      attrs: {
        "type": "number",
        "placeholder": "amount"
      },
      domProps: {
        "value": expense.amount
      },
      on: {
        "focus": function ($event) {
          return $event.target.select();
        },
        "input": function ($event) {
          if ($event.target.composing) { return; }
          _vm.$set(expense, "amount", $event.target.value);
        }
      }
    }), _c('div', {
      staticClass: "flex-grow-0 flex-shrink-0",
      staticStyle: {
        "flex-basis": "30px"
      }
    }, [index !== 0 ? _c('div', {
      staticClass: "cursor-pointer hover-grow text-xl h-full flex flex-row justify-center items-center",
      attrs: {
        "title": "Remove line"
      },
      on: {
        "click": function () {
          return _vm.removeLine(index);
        }
      }
    }, [_vm._m(0, true)]) : _vm._e()])]);
  }), 0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "color": "#60789e"
    }
  }, [_c('i', {
    staticClass: "fas fa-times-circle"
  })]);
}]

export { render, staticRenderFns }