var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "p-3 text-4xl text-center"
  }, [_vm._v("Under Construction!")]), _c('Sidebar', [_c('div', [_c('button', {
    staticClass: "button",
    on: {
      "click": _vm.newGame
    }
  }, [_vm._v(" New Game ")])]), _c('div', [_vm._v("To Move: " + _vm._s(_vm.toMoveColor))])]), _c('div', {
    staticClass: "board-holder"
  }, [_c('Board', {
    attrs: {
      "game": _vm.game
    },
    on: {
      "gameUpdate": _vm.gameUpdate
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }