var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board"
  }, _vm._l(_vm.files, function (file) {
    return _c('div', {
      key: file,
      staticClass: "file"
    }, _vm._l(_vm.ranks, function (rank) {
      return _c('div', {
        key: rank,
        staticClass: "rank"
      }, [_c('Square', {
        key: ("" + file + rank),
        attrs: {
          "rank": rank,
          "file": file,
          "piece": _vm.displayGame.board[("" + file + rank)],
          "selectedFrom": _vm.displayGame.fromSquare === ("" + file + rank),
          "selectedTo": _vm.displayGame.toSquare === ("" + file + rank),
          "lastFrom": _vm.displayGame.lastFromSquare === ("" + file + rank),
          "lastTo": _vm.displayGame.lastToSquare === ("" + file + rank),
          "onClick": _vm.clickSquare
        }
      })], 1);
    }), 0);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }