var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mainPane-container"
  }, [_vm.loadingMessage || _vm.errorMessage ? _c('LoadingOrError', {
    attrs: {
      "loadingMessage": _vm.loadingMessage,
      "errorMessage": _vm.errorMessage
    }
  }) : _c('div', {
    staticClass: "card-array slide-fade-up-grandchild"
  }, [_c('div', {
    staticClass: "flex-grow flex-shrink-0"
  }, [_c('CardTile', {
    staticClass: "flex-grow-0-imp",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_c('div', {
          staticClass: "flex-container flex justify-center"
        }, [_c('div', [_c('img', {
          staticClass: "image-display",
          attrs: {
            "src": require("../data/coverPhoto.jpeg")
          }
        })])])];
      },
      proxy: true
    }])
  }), _c('CardTile', {
    attrs: {
      "header": _vm.resumeInfo.name
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_c('div', {
          staticClass: "flex flex-col"
        }, _vm._l(_vm.resumeInfo.contactDetails, function (detail) {
          return _c('div', {
            key: detail.label,
            staticClass: "flex flex-col"
          }, [_c('div', {
            staticClass: "list-label"
          }, [_vm._v(_vm._s(detail.label))]), _c('div', {
            staticClass: "list-info"
          }, [detail.label === 'email' ? _c('a', {
            staticClass: "link",
            attrs: {
              "href": ("mailto:" + (detail.info))
            }
          }, [_vm._v(" " + _vm._s(detail.info) + " ")]) : _c('a', [_vm._v(" " + _vm._s(detail.info) + " ")])])]);
        }), 0)];
      },
      proxy: true
    }])
  }), _c('CardTile', {
    attrs: {
      "header": "Links"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return _vm._l(_vm.resumeInfo.links, function (link) {
          return _c('div', {
            key: link.title,
            staticClass: "flex flex-col"
          }, [_c('div', [_c('a', {
            staticClass: "link",
            attrs: {
              "href": link.url,
              "target": "_blank"
            }
          }, [_vm._v(_vm._s(link.title))])])]);
        });
      },
      proxy: true
    }])
  })], 1), _c('div', {
    staticClass: "flex-grow-fast flex-shrink-0"
  }, [_vm._l(_vm.resumeInfo.educationList, function (education, index) {
    return _c('span', {
      key: ("edu-" + index)
    }, [_c('CardTile', {
      scopedSlots: _vm._u([{
        key: "body",
        fn: function () {
          return [_c('div', {
            staticClass: "card-header"
          }, [_c('a', {}, [_vm._v(" " + _vm._s(education.institution.name) + " ")]), _c('a', {
            staticClass: "text-sm"
          }, [_vm._v(" class of ")]), _c('a', {}, [_vm._v(" " + _vm._s(education.graduationYear) + " ")])]), _c('div', [_vm._v(" " + _vm._s(education.degree) + " "), education.distinction ? _c('a', {
            staticClass: "text-sm"
          }, [_vm._v(" with ")]) : _vm._e(), _c('a', [_vm._v(" " + _vm._s(education.distinction) + " ")])]), _vm._l(education.majors.concat(education.minors), function (focus, index) {
            return _c('div', {
              key: ("focus-" + index)
            }, [_c('div', [_vm._v(" " + _vm._s(focus) + " ")])]);
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), _vm._l(_vm.resumeInfo.experienceList, function (experience, index) {
    return _c('span', {
      key: ("exp-" + index)
    }, [_c('CardTile', {
      attrs: {
        "header": ((experience.title) + ", " + (experience.organization)),
        "subHeader": experience.startAndEnd || ((experience.location) + " | " + (experience.start) + " — " + (experience.end || 'present'))
      },
      scopedSlots: _vm._u([{
        key: "body",
        fn: function () {
          var _experience$coreStack;
          return [_c('div', {
            staticClass: "text-left whitespace-pre-line"
          }, [Array.isArray(experience.description) ? _c('div', [_c('ul', {
            staticClass: "list-disc list-inside"
          }, _vm._l(experience.description, function (experienceDescription) {
            return _c('li', [_vm._v(" " + _vm._s(experienceDescription) + " ")]);
          }), 0)]) : _c('div', [_vm._v(_vm._s(experience.description))]), (_experience$coreStack = experience.coreStack) !== null && _experience$coreStack !== void 0 && _experience$coreStack.length ? _c('div', {
            staticClass: "flex flex-col align-center justify-center"
          }, [_c('div', {
            staticClass: "text-center font-bold"
          }, [_vm._v("Core Stack")]), _c('TagArray', {
            attrs: {
              "array": experience.coreStack.map(function (s) {
                return {
                  label: s,
                  tooltip: s
                };
              }),
              "containerClass": "justify-center"
            }
          })], 1) : _vm._e()])];
        },
        proxy: true
      }], null, true)
    })], 1);
  })], 2), _c('div', {
    staticClass: "flex-grow flex-shrink-0"
  }, [_c('CardTile', {
    attrs: {
      "header": "Core Technical Skills"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_c('TagArray', {
          attrs: {
            "array": _vm.resumeInfo.skillsCore,
            "labelKey": "name",
            "tooltipKey": "details"
          }
        })];
      },
      proxy: true
    }])
  }), _c('CardTile', {
    attrs: {
      "header": "Additional Technical Skills"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_c('TagArray', {
          attrs: {
            "array": _vm.resumeInfo.skillsAdditional,
            "labelKey": "name",
            "tooltipKey": "details"
          }
        })];
      },
      proxy: true
    }])
  }), _c('CardTile', {
    attrs: {
      "header": "Soft Skills"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_c('TagArray', {
          attrs: {
            "array": _vm.resumeInfo.skillsNonTech,
            "labelKey": "name",
            "tooltipKey": "details"
          }
        })];
      },
      proxy: true
    }])
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }