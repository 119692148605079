var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "grid grid-cols-4 gap-4"
  }, _vm._l(_vm.options, function (option) {
    return _c('div', {
      key: option.value,
      staticClass: "mx-auto flex flex-col justify-center bg-white rounded-2xl shadow-xl shadow-slate-300/60",
      class: {
        'hover-grow cursor-pointer': !option.disabled,
        'opacity-20': option.disabled
      }
    }, [_c('PlayingCard', {
      staticClass: "max-w-60 w-28 object-cover",
      attrs: {
        "cardFace": option.value,
        "title": option.value,
        "disabled": option.disabled,
        "onSelect": function () {
          return _vm.selectFunction(option.value);
        }
      }
    })], 1);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }