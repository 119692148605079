var render = function () {
  var _vm$isHandValid, _vm$isHandValid2, _vm$isHandValid3, _vm$handEntry;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-col"
  }, [_c('div', {
    staticClass: "flex flex-col"
  }, [_c('div', {
    staticClass: "p-b-10 flex justify-center"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.handEntry,
      expression: "handEntry"
    }],
    ref: "handInput",
    staticClass: "form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700\n      bg-white bg-clip-padding border border-solid border-gray-300 rounded m-1\n      transition ease-in-out m-0\n      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
    staticStyle: {
      "width": "300px"
    },
    attrs: {
      "placeholder": "Enter hand (e.g. AS,TC,4H,KD,2S)"
    },
    domProps: {
      "value": _vm.handEntry
    },
    on: {
      "focus": function () {
        _vm.isHandEntryFocused = true;
      },
      "blur": function () {
        _vm.isHandEntryFocused = false;
        _vm.isUsingSelector = false;
      },
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.handEntry = $event.target.value;
      }, _vm.handleInput],
      "keypress": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.calculatePoints($event);
      }
    }
  }), _c('button', {
    staticClass: "button m-1",
    attrs: {
      "disabled": !((_vm$isHandValid = _vm.isHandValid) !== null && _vm$isHandValid !== void 0 && _vm$isHandValid.ok),
      "title": _vm.validityMessage
    },
    on: {
      "click": _vm.calculatePoints
    }
  }, [_vm._v(" Count Points ")])]), !_vm.isUsingSelector ? _c('span', [!_vm.handEntry && _vm.isHandEntryFocused && (_vm$isHandValid2 = _vm.isHandValid) !== null && _vm$isHandValid2 !== void 0 && _vm$isHandValid2.message ? _c('div', {
    staticClass: "flex justify-center"
  }, [_vm._m(0)]) : _vm.handEntry && !_vm.isHandEntryFocused && (_vm$isHandValid3 = _vm.isHandValid) !== null && _vm$isHandValid3 !== void 0 && _vm$isHandValid3.message ? _c('div', {
    staticClass: "flex justify-center transition-smooth"
  }, [_c('div', {
    staticClass: "w-fit p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800",
    attrs: {
      "role": "alert"
    }
  }, [_c('span', {
    staticClass: "font-medium"
  }, [_vm._v(_vm._s(_vm.validityMessage))])])]) : _vm._e()]) : _vm._e()]), ((_vm$handEntry = _vm.handEntry) === null || _vm$handEntry === void 0 ? void 0 : _vm$handEntry.length) < 5 ? _c('div', {
    staticClass: "flex justify-center p-2"
  }, [_c('CribbageInput', {
    attrs: {
      "options": _vm.selectOptions,
      "selectFunction": _vm.selectCard
    }
  })], 1) : _vm._e(), this.cardsResult.totalPoints ? _c('div', [_c('div', {
    staticClass: "pt-3 text-4xl text-center"
  }, [_vm._v(" Total Points: " + _vm._s(this.cardsResult.totalPoints) + " ")]), _c('div', {
    staticClass: "flex flex-row justify-items-center justify-evenly flex-wrap mobile-one-col"
  }, [_c('Card', {
    attrs: {
      "cardTitle": "Sums",
      "cardLabel": "Sum",
      "cards": _vm.cardsResult.sums.cards,
      "points": _vm.cardsResult.sums.points
    }
  }), _c('Card', {
    attrs: {
      "cardTitle": "Pairs",
      "cardLabel": "Pair",
      "cards": _vm.cardsResult.pairs.cards,
      "points": _vm.cardsResult.pairs.points
    }
  }), _c('Card', {
    attrs: {
      "cardTitle": "Runs",
      "cardLabel": "Run",
      "cards": _vm.cardsResult.runs.cards,
      "points": _vm.cardsResult.runs.points
    }
  })], 1)]) : _vm._e()]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-fit p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800",
    attrs: {
      "role": "alert"
    }
  }, [_c('span', {
    staticClass: "font-medium"
  }, [_vm._v(" Hands should have 5 comma-separated cards, which follow the format <face><suit>. ")])]);
}]

export { render, staticRenderFns }