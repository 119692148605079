var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "square",
    class: {
      'selected-from-square': _vm.selectedFrom,
      'selected-to-square': _vm.selectedTo,
      'last-from-square': _vm.lastFrom,
      'last-to-square': _vm.lastTo,
      'dark-square': _vm.even,
      'light-square': !_vm.even
    },
    attrs: {
      "title": _vm.tooltip
    },
    on: {
      "click": function ($event) {
        return _vm.onClick(_vm.rank, _vm.file);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.pieceDisplay) + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }