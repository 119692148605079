var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ("flex flex-row flex-wrap " + (_vm.containerClass))
  }, _vm._l(_vm.array, function (item, index) {
    return _c('Tag', {
      key: index,
      attrs: {
        "label": item[_vm.labelKey],
        "tooltip": item[_vm.tooltipKey]
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }