var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loadingMessage ? _c('Loading', {
    attrs: {
      "message": _vm.loadingMessage
    }
  }) : _vm._e(), _vm.errorMessage ? _c('Error', {
    attrs: {
      "message": _vm.errorMessage
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }