var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('label', {
    staticClass: "sidebar-hamburger",
    attrs: {
      "for": "sidebar-hamburger"
    }
  }, [_vm._v("☰")]), _c('input', {
    staticClass: "hidden",
    attrs: {
      "type": "checkbox",
      "id": "sidebar-hamburger"
    }
  }), _c('div', {
    staticClass: "sidebar-container"
  }, [_c('div', [_vm._t("default")], 2)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }