var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CardTile', {
    staticStyle: {
      "min-width": "200px"
    },
    attrs: {
      "hoverGrow": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('div', {
          staticClass: "card-title flex flex-column justify-between"
        }, [_c('div', [_vm._v(_vm._s(_vm.cardTitle))]), _c('div', [_vm._v(_vm._s(_vm.points) + "pts")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function () {
        return [_c('PointsSummary', {
          attrs: {
            "label": _vm.cardLabel,
            "cards": _vm.cards
          }
        })];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }