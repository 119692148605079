var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_c('div', {
    staticClass: "menu-pane-holder"
  }), _c('div', {
    directives: [{
      name: "on-clickaway",
      rawName: "v-on-clickaway",
      value: _vm.toggleOffExpanded,
      expression: "toggleOffExpanded"
    }],
    staticClass: "menu-pane",
    class: {
      'menu-pane-expanded': _vm.isExpanded
    },
    on: {
      "Click": _vm.toggleExpanded
    }
  }, [_c('Menu', {
    attrs: {
      "sections": _vm.routes,
      "current": _vm.currentRoute,
      "toggleExpanded": _vm.toggleExpanded
    }
  })], 1), _c('div', {
    staticClass: "mainPane"
  }, [_c('router-view')], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }