var render = function () {
  var _Object$keys;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "flex flex-row flex-wrap justify-center"
  }, _vm._l(Object.entries(_vm.data), function (_ref) {
    var person = _ref[0],
      personInfo = _ref[1];
    return _c('CardTile', {
      key: person,
      ref: ("expense-list-" + person),
      refInFor: true,
      staticClass: "flex flex-col px3",
      attrs: {
        "color": personInfo.color
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function () {
          return [_c('div', [_vm.editingName === person ? _c('div', {
            staticClass: "flex flex-row flex-nowrap"
          }, [_c('input', {
            ref: ("name-edit-" + person),
            refInFor: true,
            staticClass: "form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700\n              bg-white bg-clip-padding border border-solid border-gray-300 rounded m-1\n              transition ease-in-out m-0 ml-0\n              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
            attrs: {
              "placeholder": "name"
            },
            domProps: {
              "value": person
            },
            on: {
              "blur": _vm.changeName,
              "keypress": function ($event) {
                if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                return _vm.changeName($event);
              },
              "focus": function ($event) {
                return $event.target.select();
              }
            }
          }), _c('button', {
            staticClass: "button m-1",
            attrs: {
              "title": "Remove person"
            },
            on: {
              "mousedown": function () {
                return _vm.removePerson(person);
              }
            }
          }, [_c('i', {
            staticClass: "fas fa-trash"
          })])]) : _vm._e(), _vm.editingName !== person ? _c('div', {
            staticClass: "cursor-pointer",
            attrs: {
              "title": "Tap to change name"
            },
            on: {
              "click": function () {
                return _vm.toggleNameEdit(person);
              }
            }
          }, [_vm._v(" " + _vm._s(person) + " ")]) : _vm._e()])];
        },
        proxy: true
      }, {
        key: "body",
        fn: function () {
          return [_c('div', {
            staticClass: "p-1"
          }, [_c('ExpenseList', {
            attrs: {
              "person": person,
              "expenses": personInfo === null || personInfo === void 0 ? void 0 : personInfo.expenses
            }
          })], 1)];
        },
        proxy: true
      }, {
        key: "footer",
        fn: function () {
          var _personInfo$expenses;
          return [_c('div', {
            staticClass: "flex flex-row justify-between"
          }, [_c('button', {
            staticClass: "button w-24 justify-center",
            attrs: {
              "disabled": ((_personInfo$expenses = personInfo.expenses) === null || _personInfo$expenses === void 0 ? void 0 : _personInfo$expenses.length) > 10,
              "title": ("Add line for " + person)
            },
            on: {
              "click": function () {
                return _vm.addLineForPerson(person);
              }
            }
          }, [_c('i', {
            staticClass: "fas fa-plus"
          })]), _c('div', {
            staticClass: "text-right pr-10px self-center font-bold"
          }, [_vm._v(" " + _vm._s(_vm.formatDollar(_vm.personTotals[person])) + " ")])])];
        },
        proxy: true
      }], null, true)
    });
  }), 1), _c('div', {
    staticClass: "flex justify-center"
  }, [_c('button', {
    staticClass: "button m-1 w-32",
    attrs: {
      "disabled": _vm.data[_vm.defaultName] || ((_Object$keys = Object.keys(_vm.data)) === null || _Object$keys === void 0 ? void 0 : _Object$keys.length) > 10,
      "title": "Add person"
    },
    on: {
      "click": _vm.addPerson
    }
  }, [_c('i', {
    staticClass: "fas fa-plus"
  })])]), _c('div', {
    staticClass: "flex flex-row flex-wrap justify-center result-card"
  }, [_c('CardTile', {
    staticClass: "flex flex-col px3",
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('div', [_vm._v("Total: " + _vm._s(_vm.formatDollar(_vm.totalExpense)))]), _c('div', [_vm._v("Per Person: " + _vm._s(_vm.formatDollar(_vm.perPersonExpense)))])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function () {
        return [_c('div', {
          staticClass: "flex flex-col"
        }, [_c('div', {
          staticClass: "table-row"
        }, [_c('div', {
          staticClass: "border-b"
        }, [_vm._v(" Name ")]), _c('div', {
          staticClass: "border-b"
        }, [_vm._v(" Expense ")]), _c('div', {
          staticClass: "border-b basis-50"
        }, [_vm._v(" Payouts ")])])]), _vm._l(Object.keys(_vm.data), function (person) {
          return _c('div', {
            staticClass: "table-row"
          }, [_c('div', [_vm._v(" " + _vm._s(person) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.formatDollar(_vm.personTotals[person])) + " (" + _vm._s(_vm.formatDollar(_vm.perPersonBalance[person])) + ") ")]), _c('div', {
            staticClass: "flex flex-col flex-nowrap basis-50"
          }, _vm._l(_vm.payouts[person], function (payout) {
            return _c('div', [_c('a', [_vm._v(_vm._s(_vm.formatDollar(payout.amount)))]), _c('a', {
              staticClass: "mx-0.5"
            }, [_c('i', {
              staticClass: "fas fa-arrow-right"
            })]), _c('a', [_vm._v(_vm._s(payout.to))])]);
          }), 0)]);
        })];
      },
      proxy: true
    }])
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }