var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('img', {
    attrs: {
      "src": require(("@/data/cards/" + (_vm.cardFace === 'AD' ? 'AceD' : _vm.cardFace) + ".svg"))
    },
    on: {
      "click": function () {
        if (!_vm.disabled) { _vm.onSelect(); }
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }