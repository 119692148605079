var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "fan",
    attrs: {
      "data-fan": "spacing: 0.1; width: 80; radius: 80; cards: AS,KS"
    }
  }), _vm._l(_vm.cards, function (set, index) {
    return _c('div', {
      key: index
    }, [_c('div', {
      staticClass: "hand hhand-compact active-hand flex justify-center"
    }, _vm._l(set, function (cardFace) {
      return _c('img', {
        key: cardFace,
        staticClass: "card transition-smooth",
        staticStyle: {
          "--duration": ".2s"
        },
        attrs: {
          "src": require(("@/data/cards/" + cardFace + ".svg"))
        }
      });
    }), 0)]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }