var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "menu",
    on: {
      "click": _vm.toggleExpanded
    }
  }, _vm._l(_vm.sections, function (section) {
    return _c('div', {
      key: section.path,
      staticClass: "menu-item-spacer",
      class: {
        'sort-item-top': _vm.isCurrent(section.path)
      }
    }, [section.isExternal ? _c('a', {
      attrs: {
        "href": section.path
      }
    }, [_c('div', {
      staticClass: "menu-item",
      class: {
        'menu-item-selected': _vm.isCurrent(section.path)
      },
      staticStyle: {
        "--scale": "1.01"
      }
    }, [_vm._v(" " + _vm._s(section.name) + " ")])]) : _vm._e(), _c('router-link', {
      attrs: {
        "to": section.path
      }
    }, [_c('div', {
      staticClass: "menu-item",
      class: {
        'menu-item-selected': _vm.isCurrent(section.path)
      },
      staticStyle: {
        "--scale": "1.01"
      }
    }, [_vm._v(" " + _vm._s(section.name) + " ")])])], 1);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }